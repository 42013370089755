import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import { checkOS } from "./system";
import { Dialog } from 'vant';
import store from '@/store';
import { getToken, getTopic, getAutToken } from '@/utils/auth';
import router from '@/router';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = getToken();
    config.headers['outToken'] = getAutToken();
    config.headers['topic'] ? '' : config.headers['topic'] = getTopic('ZHGC');
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0 && res.code !== 200) {
    if (checkOS() !== 'pc') {
      Dialog.alert({
        title: '提示',
        message: res.message || '网络错误，请稍后再试',
        theme: 'round-button'
      }).then(function () {
        // on close
      });
    } else {
      var path = router.app._route.path;
      if (path !== '/energyMonitoring/conditionMonitoring') {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        });
      }
    }
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      if (checkOS() !== 'pc') {
        Dialog.alert({
          title: '警告',
          message: '久时间未操作，请重新登录'
        }).then(function () {
          store.dispatch('user/resetToken').then(function () {
            var sewage = sessionStorage.getItem('HsLogin');
            var quarantine = sessionStorage.getItem('quarantineLogin');
            var ssoLogin = sessionStorage.getItem('ssoLogin');
            var authLogin = sessionStorage.getItem('authLogin');
            if (sewage === '1') {
              router.push("/sewageLogin?redirect=".concat(router.currentRoute.fullPath));
            } else if (quarantine === '1') {
              router.push("/quarantineLogin?redirect=".concat(router.currentRoute.fullPath));
            } else if (ssoLogin === 'ssoLogin') {
              window.location.replace('http://10.106.34.231/ssoLogin');
            } else if (authLogin === 'authLogin') {
              window.location.replace('http://10.106.34.231/authLogin');
            } else {
              location.reload();
            }
          });
        });
      } else {
        // to re-login
        MessageBox.alert('久时间未操作，请重新登录', '警告', {
          confirmButtonText: '重新登录',
          callback: function callback() {
            store.dispatch('user/resetToken').then(function () {
              var sewage = sessionStorage.getItem('HsLogin');
              var quarantine = sessionStorage.getItem('quarantineLogin');
              var ssoLogin = sessionStorage.getItem('ssoLogin');
              var authLogin = sessionStorage.getItem('authLogin');
              if (sewage === '1') {
                router.push("/sewageLogin?redirect=".concat(router.currentRoute.fullPath));
              } else if (quarantine === '1') {
                router.push("/quarantineLogin?redirect=".concat(router.currentRoute.fullPath));
              } else if (ssoLogin === 'ssoLogin') {
                window.location.replace('http://10.106.34.231/ssoLogin');
              } else if (authLogin === 'authLogin') {
                window.location.replace('http://10.106.34.231/authLogin');
              } else {
                location.reload();
              }
            });
          }
        });
      }
    } else if (res.code === 1001) {
      if (checkOS() !== 'pc') {
        Dialog.alert({
          title: '警告',
          message: res.message
        }).then(function () {
          window.location.replace(window.location.origin + '/mobile/boothConstruction/login');
        });
      }
    }
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  // const status = sessionStorage.getItem('close')
  // status === '0'
  var path = router.app._route.path;
  if (path === '/code/personnelSettingIndex' || path === '/code/codeTrace') {
    if (error.message.includes('404')) {
      return Promise.reject(error);
    }
  }
  console.log('err' + error); // for debug
  if (error.message.includes('timeout')) {
    Message({
      message: '网络加载超时，请稍后重试',
      type: 'error',
      duration: 5 * 1000
    });
  } else if (error.message.includes('500')) {
    Message({
      message: '服务已离线，请联系管理员',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
export default service;