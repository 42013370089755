import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { constantRoutes, pcRoutes, mobileRoutes } from '@/router';
import { getAppMenusByMenusIdApi, getMenusAuthTreeApi, getMenusByApplicationApi } from '@/api/baseApp/menus';
import Layout from '@/layout';
import MobileLayout from '@/mobileLayout';
import { getAppId, removeAppId, setAppId } from '@/utils/auth';
import { checkOS } from '@/utils/system';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
export function getRouterList(routerItem, parentPath, routerList) {
  if (!routerItem.component) {
    if (routerItem.clientType === '1') {
      routerItem.component = Layout;
    } else if (routerItem.clientType === '2') routerItem.component = MobileLayout;
  } else {
    if (routerItem.component) {
      var uri = routerItem.component;
      routerItem.component = function () {
        return import("@/views".concat(uri));
      };
      // routerItem.component = (resolve) => require([`@/views${uri}`], resolve)
    }
  }
  routerItem.meta.noCache = routerItem.meta.noCache === 0;
  routerItem.hidden = routerItem.hidden === 0;
  routerItem.children = !routerItem.children ? [] : routerItem.children;
  if (parentPath) {
    if (routerItem.path[0] === '/') {
      parentPath = routerItem.path;
    } else {
      parentPath += '/' + routerItem.path;
    }
  } else {
    parentPath += routerItem.path;
  }
  routerList.push(parentPath);
  if (routerItem.children && routerItem.children.length > 0) {
    routerItem.children.forEach(function (item) {
      return getRouterList(item, parentPath, routerList);
    });
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  routeList: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(checkOS() === 'pc' ? pcRoutes : mobileRoutes).concat(routes);
  },
  SET_ROUTE_LIST: function SET_ROUTE_LIST(state, routeList) {
    state.routeList = routeList;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getMenusAuthTreeApi().then(function (response) {
        var routerList = [];
        var accessedRoutes = response.data;
        accessedRoutes.forEach(function (item) {
          getRouterList(item, '', routerList);
        });
        commit('SET_ROUTE_LIST', routerList);
        if (getAppId()) {
          // 加载应用菜单
          getMenusByApplicationApi({
            id: getAppId()
          }).then(function (response) {
            var accessedRoutes = response.data;
            accessedRoutes.forEach(function (item) {
              getRouterList(item, '', []);
            });
            commit('SET_ROUTES', response.data);
          }).catch(function () {
            reject();
          });
        } else {
          commit('SET_ROUTES', []);
        }
        resolve(accessedRoutes);
      }).catch(function () {
        reject();
      });
    });
  },
  loadRoutes: function loadRoutes(_ref2, id) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      setAppId(id);
      getMenusByApplicationApi({
        id: id
      }).then(function (response) {
        var accessedRoutes = response.data;
        accessedRoutes.forEach(function (item) {
          getRouterList(item, '', []);
        });
        commit('SET_ROUTES', response.data);
        resolve(response);
      }).catch(function () {
        reject();
      });
    });
  },
  getAppMenus: function getAppMenus(_ref3, menuId, clientType) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      console.log(menuId);
      getAppMenusByMenusIdApi({
        menuId: menuId,
        clientType: clientType
      }).then(function (response) {
        if (response.data.appId) {
          setAppId(response.data.appId);
          var accessedRoutes = response.data.menus;
          accessedRoutes.forEach(function (item) {
            getRouterList(item, '', []);
          });
          commit('SET_ROUTES', response.data);
        } else {
          removeAppId();
          commit('SET_ROUTES', []);
        }
        resolve(response);
      }).catch(function () {
        reject();
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};